import React, { useEffect, useRef } from 'react';
import { Widget, addLinkSnippet, addResponseMessage, addBotMessage, addUserMessage, setQuickButtons, isWidgetOpened, toggleWidget, toggleMsgLoader, renderCustomComponent } from '@pinguin/web-chat-widget';
import io from 'socket.io-client';
import ReactDOM from 'react-dom';
import Configuration from './Config';
import Message from './Models/Message';
import Language from './Language';
import ConnectionError from './Components/ConnectionError';
import '@pinguin/web-chat-widget/styles.css';
import minimizeImage from './Images/minimize.png';
import aidaAvatar from './Images/aida_avatar_ebuero.png';
import agentAvatar from './Images/secretary_avatar_ebuero.png';
import './Themes/Default.css';

const chatServerHost = process.env.REACT_APP_CHAT_SERVER_HOST;
const chatServerSocketPath = process.env.REACT_APP_CHAT_SERVER_SOCKET_PATH;
const configInstance = Configuration.getInstance();
const errorMessage = configInstance.checkEnvironment();

let themeColor;
switch (configInstance.theme) {
  case "blue":
    themeColor = "#006199";
    break;
  case "green":
    themeColor = "#00994d";
    break;
  case "orange":
    themeColor = "#d76339";
    break;
  default:
    if (/^#[0-9A-F]{6}$/i.test(configInstance.theme)) {
      themeColor = configInstance.theme;
    } else {
      themeColor = "#990000";
    }
}


document.documentElement.style.setProperty('--theme-color', themeColor);

if (errorMessage != null) {
  ReactDOM.render(errorMessage, document.getElementById('web-chat-widget'));
  throw new Error("Missing configuration");
}

const languageInstance = Language.getInstance();
languageInstance.setLanguage(configInstance.language);


function App() {
  const messagePlaceholder = languageInstance.getText("messagePlaceholder");

  const isShowingMessageLoader = useRef(false);
  const clearButtons = [];
  const socket = io(chatServerHost, {
    path: chatServerSocketPath,
    transports: ["websocket", "polling"],
    query: {
      userId: configInstance.channelId + configInstance.userId,
      channelId: configInstance.channelId,
      userToken: configInstance.userToken,
      language: configInstance.language,
      trackingData: configInstance.trackingData
    },
  });
  useEffect(() => {
    const oneSessionAllowedPlaceholder = languageInstance.getText("oneSessionAllowedPlaceholder");
    console.log("Initializing widget:" + process.env.REACT_APP_VERSION);
    if (!isWidgetOpened() && configInstance.popupBehavior !== "minimized") toggleWidget();
    else if (isWidgetOpened() && configInstance.popupBehavior === "minimized") toggleWidget();
    processHistory();
    if ((configInstance.getChatMinimizedStatus() === true) && isWidgetOpened()) {
      toggleWidget();
    }
 
    socket.emit('subscribe', { topic: 'general' });
    socket.on('greetingFromServer', (data) => {

      const greetingObject = JSON.parse(data.message);

      if (isShowingMessageLoader.current === true) {
        toggleMsgLoader();
        isShowingMessageLoader.current = false;
      }

      if (greetingObject.greetingMessage) {

        const messageHistory = configInstance.getHistory();
        if ((messageHistory) && (messageHistory.length > 0)) return;

        addBotMessage(greetingObject.greetingMessage);
        processButtons(greetingObject.options);
      }


    });

    socket.on('messageFromServer', (data) => {

      if (isShowingMessageLoader.current === true) {
        toggleMsgLoader();
        isShowingMessageLoader.current = false;
      }

      if ((data.message.text === '/close-chat') && (data.message.messageSource === 'SYSTEM')) {
        configInstance.setChatActiveStatus(false);
        return;
      }

      if ((data.message.messageSource === 'BOT') || (data.message.messageSource === 'SYSTEM')) {
        addBotMessage(data.message.text);
      }
      else {
        addResponseMessage(data.message.text);
      }
      processLinks(data.message.text);
      if (data.message.extraFields !== undefined) {
        processButtons(data.message.extraFields);
      }
      configInstance.addToHistory(new Message(data.message.text, data.message.messageSource, "MSG", data.message.timestamp));
    });

    socket.on("connect_error", (error) => {
      renderCustomComponent(ConnectionError);
      console.log("Reconnection failed! " + error);
    });

    socket.on('disconnectFromServer', (data) => {
      if (data.message != null) {
        if (data.message.text === 'Disconnecting....') {
          configInstance.setChatActiveStatus(false);
          addBotMessage(oneSessionAllowedPlaceholder);
          socket.disconnect();
          socket.removeAllListeners();
          document.body.classList.add('app-disabled');
        }
      }

      socket.disconnect();
    });

    socket.on("connect", () => {
      console.log("Connected!");
    });

    socket.on("disconnect", () => {
      configInstance.setChatActiveStatus(false);
      console.log("Disconnected!");
    });


    return () => {
      socket.disconnect();
    }
  }, [socket]);


  function processHistory() {
    const messageHistory = configInstance.getHistory();
    if ((messageHistory) && (messageHistory.length > 0)) {
      let shouldExpireAll = false;
      for (let message of messageHistory) {
        switch (message.source) {
          case 'USER':
            if (message.type === 'MSG') addUserMessage(message.text);
            break;
          case 'BOT':
            if (message.type === 'MSG') addBotMessage(message.text);
            else if (message.type === 'LINK') addLinkSnippet({
              link: message.text,
              title: 'Link',
              target: '_blank',
            });
            break;
          case 'SYSTEM':
            if (message.type === 'MSG') addBotMessage(message.text);
            else if (message.type === 'LINK') addLinkSnippet({
              link: message.text,
              title: 'Link',
              target: '_blank',
            });
            break;
          default:
            addResponseMessage(message.text);
        }
        let diff = new Date().getTime() - message.timestamp;
        if (!isNaN(diff) && (diff > (2000 * 60 * 60))) {
          shouldExpireAll = true;
        }
      }
      if (shouldExpireAll) configInstance.removeHistory();
    }

  }

  function processLinks(text) {
    const urlRegex = /(https?:\/\/[^\s]+[^.])/g;
    const matches = text.match(urlRegex);

    if (matches && matches.length > 0) {
      matches[0] = matches[0].trim();
      if (matches[0].charAt(matches[0].length - 1) === '.') {
        matches[0] = matches[0].substring(0, matches[0].length - 1);
      }
      const linkData = {
        link: matches[0],
        title: 'Link',
        target: '_blank',
      };
      addLinkSnippet(linkData);
    }

  }

  function processButtons(extraFields) {
    if (extraFields === null) return;
    if (extraFields.length === 0) return;
    let myButtons = [];
    for (let button of extraFields) {
      if (button.contentType === 'button') {
        myButtons.push({ label: button.content, value: button });
      }
      else if (button.contentType === 'weblink') {
        myButtons.push({ label: button.content, value: button });
      }
      else {
        myButtons.push({ label: button, value: button });
      }
    }
    setQuickButtons(myButtons);
  }

  const handleNewUserMessage = (newMessage) => {
    configInstance.setChatActiveStatus(true);
    socket.emit('messageFromClient', { message: newMessage });
    configInstance.addToHistory(new Message(newMessage, "USER", "MSG", null));
    if (isShowingMessageLoader.current === false) {
      toggleMsgLoader();
      isShowingMessageLoader.current = true;
    }
    setQuickButtons(clearButtons);
  };

  const handleQuickButtonClicked = (newMessage) => {
    configInstance.setChatActiveStatus(true);
    if (isShowingMessageLoader.current === false) {
      toggleMsgLoader();
      isShowingMessageLoader.current = true;
    }
    if (newMessage.contentType === 'button') {
      addUserMessage(newMessage.content);
      socket.emit('buttonFromClient', { newMessage });
      configInstance.addToHistory(new Message(newMessage.content, "USER", "MSG", null));
    }
    else if (newMessage.contentType === 'weblink') {

      if (isShowingMessageLoader.current === true) {
        toggleMsgLoader();
        isShowingMessageLoader.current = false;
      }
      const linkData = {
        link: newMessage.payload,
        title: 'Link',
        target: '_blank',
      };
      addLinkSnippet(linkData);
      window.open(newMessage.payload, '_blank');
      configInstance.addToHistory(new Message(newMessage.payload, "BOT", "LINK", null));
    }

    setQuickButtons(clearButtons);
  }

  return (
    <div className="rcw-widget">
      <Widget
        handleNewUserMessage={handleNewUserMessage}
        handleQuickButtonClicked={handleQuickButtonClicked}
        profileAgentAvatar={agentAvatar}
        profileBotAvatar={aidaAvatar}
        title={configInstance.title}
        subtitle={configInstance.subtitle}
        showTimeStamp="true"
        emojis="true"
        resizable="false"
        launcherCloseImg={minimizeImage}
        showCloseButton="false"
        senderPlaceHolder={messagePlaceholder}
      />
    </div>
  );

}

export default App;
