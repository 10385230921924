import React from "react";
import ReactDOM, { render } from "react-dom";
import App from "./App";
import Configuration from './Config';
import minimizeImage from './Images/minimize.png'
import terminateImage from './Images/terminate.png'
import bubleChatImage from './Images/bubble-chat.png';

const configInstance = Configuration.getInstance();

function removeLauncher() {
    const isOpenLauncherExist = document.querySelector('.rcw-conversation-container');
    if (isOpenLauncherExist) {
        const launcher = document.getElementsByClassName("rcw-launcher");
        launcher[0].style.display = "none";
    }
}
function createTopControls() {

    const rcwHeaderDiv = document.querySelector('.rcw-header');
    if (rcwHeaderDiv == null) return;
    const topControls = document.createElement('div');
    topControls.className = 'rcw-top-controls';

    createBubbleChatIcon(topControls);
    const topRightButtons = document.createElement('div');

    createMinimizeButton(topRightButtons);
    if (configInstance.closeButtonVisible) createTerminateButton(topRightButtons);
    topControls.appendChild(topRightButtons);

    const firstChild = rcwHeaderDiv.firstChild;
    if (firstChild.className !== topControls.className) {
        rcwHeaderDiv.insertBefore(topControls, firstChild);
    }

    const closeButton = document.querySelector('.rcw-close-button');
    if (closeButton) closeButton.parentNode.removeChild(closeButton);

    const rcwHide = document.querySelector('.rcw-hide-sm');
    if (rcwHide) window.isWebChatWidgetClosed = false;

}

function createBubbleChatIcon(buttonHolder) {
    const img = document.createElement('img');
    img.className = 'rcw-bubble-chat-icon';
    img.src = bubleChatImage;
    buttonHolder.appendChild(img);
}

function createMinimizeButton(buttonHolder) {
    const button = document.createElement('button');
    button.className = 'rcw-minimize-button';
    const img = document.createElement('img');
    img.src = minimizeImage;
    img.alt = "minimize";
    button.appendChild(img);
    button.onclick = function () {
        const launcher = document.getElementsByClassName("rcw-launcher");
        launcher[0].style.display = "block";
        const rcwHideButton = document.querySelector('.rcw-hide-sm');
        rcwHideButton.click();
        configInstance.setChatMinimizedStatus(true);
    }

    buttonHolder.appendChild(button);
}

function createTerminateButton(buttonHolder) {
    const button = document.createElement('button');
    button.className = 'rcw-terminate-button';
    const img = document.createElement('img');
    img.src = terminateImage;
    img.alt = "terminate";
    button.appendChild(img);
    button.onclick = function () {
        if (configInstance.popupBehavior === 'EVA') {
            const webchatwidget = document.getElementById("web-chat-widget");
            ReactDOM.unmountComponentAtNode(webchatwidget);
            return;
        }
        const launcher = document.getElementsByClassName("rcw-launcher");
        launcher[0].style.display = "block";
        const rcwHideButton = document.querySelector('.rcw-hide-sm');
        rcwHideButton.click();
        window.isWebChatWidgetClosed = true;
        configInstance.setChatActiveStatus(false);
        window.isWebChatWidgetActive = false;
    }
    buttonHolder.appendChild(button);

}

function displayWebChatWidget() {
    const button = document.querySelector('button.rcw-launcher');
    if (button && !button.classList.contains('rcw-hide-sm')) {
        button.click();
        window.isWebChatWidgetClosed = false;
    }
}

function hideWebChatWidget() {
    const button = document.querySelector('button.rcw-launcher');
    if (button && button.classList.contains('rcw-hide-sm')) {
        button.click();
    }
}

function startWebChatWidget(trackingData) {
    window.isWebChatWidgetClose = false;
    if (trackingData !== undefined) {
        try {
            configInstance.trackingData = JSON.stringify(trackingData);
        }
        catch {
            configInstance.trackingData = null;
        }
    }

    const webchatwidget = document.getElementById("web-chat-widget");
    render(<App />, webchatwidget);
    createTopControls();

    const targetNode = document.querySelector('.rcw-widget-container');

    const observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
            if (mutation.type === 'childList') {
                createTopControls();
                if (configInstance.popupBehavior === 'EVA') removeLauncher();
            }
        });
    });
    const config = { childList: true, subtree: true };
    observer.observe(targetNode, config);

    if (configInstance.popupBehavior === 'EVA') {
        const launcher = document.getElementsByClassName("rcw-launcher");
        launcher[0].style.display = "none";
    }

    document.querySelector('.rcw-launcher').addEventListener('click', function() {
        if (configInstance.getChatMinimizedStatus()===true) configInstance.setChatMinimizedStatus(false);
        else configInstance.setChatMinimizedStatus(true);
    });

}
if (configInstance.popupBehavior === 'EVA' || configInstance.popupBehavior === 'silent') {
    window.startWebChatWidget = startWebChatWidget;
    window.displayWebChatWidget = displayWebChatWidget;
    window.hideWebChatWidget = hideWebChatWidget;
    window.isWebChatWidgetClosed = true;
    window.isWebChatWidgetActive = configInstance.getChatActiveStatus();

}

else {
    window.isWebChatWidgetClosed = false;
    window.isWebChatWidgetActive = configInstance.getChatActiveStatus();
    startWebChatWidget();
}


